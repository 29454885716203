
































import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  name: 'qa-page'
})
export default class extends Vue {

}
